<template>
    <seccion-datos :titulo="datos" @cambios="guardar()">
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group">
                        <label >{{ $t('general.nombre') }}</label>
                        <input type="text" v-model="nombre" class="form-control">
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label >{{ $t('general.poblacion') }}</label>
                        <input type="text" v-model="poblacion_operario" class="form-control">
                    </div>                 
                </div>
            </div>
                <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label>{{ $t('general.direccion') }}</label>
                                <input type="text" v-model="direccion" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{ $t('general.cpostal') }}</label>
                                <input type="text" v-model="postal_operario" class="form-control">
                            </div>
                        </div>
                </div>
                <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t('general.provincia') }}</label>
                                <input type="text" v-model="provincia_operario" class="form-control">
                            </div>
                        </div> 
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t('general.telefono') }}</label>
                                <input type="text" v-model="telefono1" class="form-control">
                        </div>
                        </div>
                </div>
                <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>NIF</label>
                                <select v-model="tipo_identificacion" class="form-control">
                                    <option value="N.I.F.">N.I.F.</option>
                                    <option value="C.I.F.">C.I.F.</option>
                                    <option value="N.I.E.">N.I.E.</option>
                                    <option value="OTROS">{{ $t('general.otros') }}</option>
                            </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>&nbsp;</label>
                                <input type="text" v-model="nif" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label >EMail</label>
                                <input type="text" v-model="email" class="form-control">
                            </div>
                        </div>
                </div>
                <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t('general.movil') }}</label>
                                <input type="text" v-model="telefono2" class="form-control">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t('general.fax') }}</label>
                                <input type="text" v-model="fax" class="form-control">
                            </div>
                        </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>IBAN</label>
                            <div class="input-group mb-6">
                                <input type="text" v-model="iban_1_operario" class="form-control form-control-sm" style="width:20px">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" v-model="iban_2_operario" class="form-control form-control-sm">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" v-model="iban_3_operario" class="form-control form-control-sm">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" v-model="iban_4_operario" class="form-control form-control-sm">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" v-model="iban_5_operario" class="form-control form-control-sm">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text" v-model="iban_6_operario" class="form-control form-control-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>CCC</label>
                            <div class="input-group mb-6">
                                <input type="text" v-model="ccc1" class="form-control form-control-sm" style="width:20px">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text"  v-model="ccc2" class="form-control form-control-sm">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text"  v-model="ccc3" class="form-control form-control-sm">
                                <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                                <input type="text"  v-model="ccc4" class="form-control form-control-sm">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('general.personacontacto') }}</label>
                            <input type="text" v-model="persona_contacto" class="form-control">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ $t('general.subcont') }}</label>
                            <input type="text" v-model="subcuenta_operario" class="form-control">
                        </div>
                    </div>
                </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="preferente" v-model="preferente_operario" v-bind:true-value="1"
                            v-bind:false-value="0">
                        <label for="preferente" class="custom-control-label">{{ $t('general.preferente') }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="datosperito" class="custom-control-input" v-model="mostrar_datos_perito"
                            v-bind:true-value="1" v-bind:false-value="0">
                        <label for="datosperito" class="custom-control-label">{{ $t('general.mostrardatosperito') }}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" id="1visita"  v-bind:true-value="1"
                            v-bind:false-value="0">
                        <label for="1visita" class="custom-control-label">{{ $t('general.puededarprimeravisita') }}</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="2visita" class="custom-control-input" 
                            v-bind:true-value="1" v-bind:false-value="0">
                        <label for="2visita" class="custom-control-label">{{ $t('general.puededarsegundavisita') }}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" id="autopresu" class="custom-control-input" v-model="autoaprobado_operario"
                                    v-bind:true-value="1" v-bind:false-value="0">
                                <label for="autopresu" class="custom-control-label">{{ $t('general.autoaprovadospresupuestos') }}</label>
                            </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ $t('general.generapresupuestos') }}</label>
                        <select v-model="genera_presupuestos" class="form-control">
                            <option value="2">{{ $t('general.nogenera') }}</option>
                            <option value="1">{{ $t('general.conbaremooperario') }}</option>
                            <option value="0">{{ $t('general.conbaremocompania') }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6">
                    
                </div>
            </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>{{ $t('general.observaciones') }}</label>
                <textarea v-model="observaciones_operario" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div  v-if="acceso">
            <br>
            <h5 style="font-weight: bold;"> Datos de usuario</h5>
            <div class="row">
                <div class="col-md-7">
                    <div class="form-group">
                        <label >{{ $t('general.usuario') }}</label>
                        <input type="text" class="form-control nombre_completo dato" id="nombre_completo3" v-model="login" autocomplete="off">
                    </div>
                    
                </div>
                <div class="col-md-5">
                    <div class="form-group">
                        <label >{{ $t('general.clave') }}</label>
                        <input type="password" class="form-control nombre_completo dato" v-model="clave" id="nombre_completo123" autocomplete="new-password">
                    </div>   
                </div>
            </div>
        </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'operario', 'acceso'],
    data() {
        return {
            login:'',
            clave:'',
            nombre: '',
            fax:'',
            telefono1: '',
            telefono2: '',
            nif: '',
            email: '',
            direccion: '',
            postal_operario: '',
            poblacion_operario: '',
            provincia_operario: '',
            tipo_identificacion: '',
            iban_1_operario: '',
            iban_2_operario: '',
            iban_3_operario: '',
            iban_4_operario: '',
            iban_5_operario: '',
            iban_6_operario: '',
            ccc1: '',
            ccc2: '',
            ccc3: '',
            ccc4: '',
            persona_contacto: '',
            subcuenta_operario: '',
            preferente_operario: '',
            mostrar_datos_perito: '',
            autoaprobado_operario: '',
            genera_presupuestos: '',
            observaciones_operario: '',
            datos:"Datos",
            
        }
    },
    methods: {
        cargarDatosOperario(num) {
            console.log('num', num, this.operario);
            this.$store.dispatch('cambiarCargando', true);
                this.nombre = this.operario.nombre;
                this.fax = this.operario.fax;
                this.telefono1 = this.operario.telefono1;
                this.telefono2 = this.operario.telefono2;
                this.nif = this.operario.nif;
                this.email = this.operario.email;
                this.direccion = this.operario.direccion;
                this.postal_operario = this.operario.postal_operario;
                this.poblacion_operario = this.operario.poblacion_operario;
                this.provincia_operario = this.operario.provincia_operario;
                this.tipo_identificacion = this.operario.tipo_identificacion,
                this.iban_1_operario = this.operario.iban_1_operario;
                this.iban_2_operario = this.operario.iban_2_operario;
                this.iban_3_operario = this.operario.iban_3_operario;
                this.iban_4_operario = this.operario.iban_4_operario;
                this.iban_5_operario = this.operario.iban_5_operario;
                this.iban_6_operario = this.operario.iban_6_operario;
                this.ccc1 = this.operario.numero_entidad;
                this.ccc2 = this.operario.numero_oficina;
                this.ccc3 = this.operario.numero_dc;
                this.ccc4 = this.operario.numero_cuenta;
                this.persona_contacto = this.operario.persona_contacto;
            this.subcuenta_operario = this.operario.subcuenta_operario;
            this.preferente_operario = this.operario.preferente_operario;
            this.mostrar_datos_perito = this.operario.mostrar_datos_perito;
            this.autoaprobado_operario = this.operario.autoaprobado_operario;
            this.genera_presupuestos = this.operario.genera_presupuestos;
            this.observaciones_operario = this.operario.observaciones_operario;
            this.datos = this.$t("general.datos");
           this.$store.dispatch('cambiarCargando', false);
            
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/operarios/:id */
            const api = new PwgsApi;
            const subidadatos = { nombre: this.nombre, fax: this.fax, telefono1: this.telefono1, telefono2: this.telefono2, nif: this.nif, email: this.email, direccion: this.direccion, postal_operario: this.postal_operario, poblacion_operario: this.poblacion_operario, provincia_operario: this.provincia_operario, tipo_identificacion: this.tipo_identificacion, iban_1_operario: this.iban_1_operario, iban_2_operario: this.iban_2_operario, iban_3_operario: this.iban_3_operario, iban_4_operario: this.iban_4_operario, iban_5_operario: this.iban_5_operario, iban_6_operario: this.iban_6_operario, persona_contacto: this.persona_contacto, subcuenta_operario: this.subcuenta_operario, numero_entidad: this.ccc1, numero_oficina: this.ccc2, numero_dc: this.ccc3, numero_cuenta: this.ccc4, preferente_operario: this.preferente_operario, mostrar_datos_perito: this.mostrar_datos_perito, autoaprobado_operario: this.autoaprobado_operario, genera_presupuestos: this.genera_presupuestos, observaciones_operario: this.observaciones_operario, };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('operarios/' + this.operario.id, subidadatos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/operarios */
                try {
                    const resp = await api.post('operarios', subidadatos);
                    await api.post('usuarios', {operarios_idoperario:resp.id, nombre: this.login, contraseña: this.clave});
                    console.log('rrr', resp);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/operarios/' + resp.id);
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            
        }
    },
    mounted() {
        this.cargarDatosOperario(1);
    },
    watch: {
        operario() {
            this.cargarDatosOperario(2);
        }
    },
}
</script>
<style>
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    color: #555;
    background-color: #e7e7e7;
}
</style>